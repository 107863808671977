import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Layout from "../layouts/default"
import SEO from "../components/SEO"
import Jumbotron from "../components/Jumbotron"
import Gallery from "../components/Gallery"
import ArrowIcon from "~/icons/aarow.inline.svg"

export default class IndexPage extends React.Component {
  get articles() {
    return this.props.data.allContentfulArticle.edges.map(edge => edge.node)
  }

  get jumbotron() {
    return this.props.data.contentfulHome.jumbotron.jumbotron
  }

  get headshot() {
    return this.props.data.contentfulAsset
  }

  render() {
    return (
      <Layout>
        <SEO thumbnail={this.headshot} />
        <FullHeightJumbotron>{this.jumbotron}</FullHeightJumbotron>
        <Wrapper>
          {this.renderScrollHint()}
          {this.renderGallery()}
        </Wrapper>
      </Layout>
    )
  }

  renderScrollHint() {
    return (
      <PageHint id="work">
        <Link to="/#work">Projects</Link>
        <ArrowIcon />
      </PageHint>
    )
  }

  renderGallery() {
    const items = this.articles.map(article => {
      return {
        image: article.cover,
        link: `/${article.slug}`,
        title: article.title,
        subtitle: article.description,
      }
    })
    return <Gallery items={items} />
  }
}

const FullHeightJumbotron = styled(Jumbotron)`
  height: calc(100vh - 144px);
`

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  margin: 0 auto;
  padding: 0 var(--content-padding-vertical);
`

const PageHint = styled.div`
  font-family: var(--low-emphasis-font-family);
  font-weight: var(--low-emphasis-font-weight);
  text-transform: uppercase;
  margin-bottom: calc(var(--single-spacing) * 5);
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;

  a {
    color: var(--medium-emphasis-color);
  }

  svg {
    margin-top: var(--single-spacing);
    diplay: block;
  }
`

export const query = graphql`
  query IndexPageQuery {
    allContentfulArticle {
      edges {
        node {
          cover {
            fluid(maxWidth: 612) {
              ...GatsbyContentfulFluid
            }
            title
          }
          description
          title
          slug
        }
      }
    }
    contentfulHome(contentful_id: { eq: "5lQX5G6IW18z0m6YJ8FJwF" }) {
      jumbotron {
        jumbotron
      }
    }
    contentfulAsset(contentful_id: { eq: "4cELui0WBH1v6Vo8BXjQE1" }) {
      fixed {
        src
        height
        width
      }
    }
  }
`
