import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"

export default function Gallery(props) {
  const { items } = props
  return (
    <Wrapper {...props}>
      {items.map((item, index) => (
        <GalleryItem key={index} {...item} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: -8px;
`

function GalleryItem(props) {
  const { image, link, title, subtitle } = props
  return (
    <StyledLink to={link}>
      <div className="stretched">
        <BackgroundImage
          className="full-size"
          fluid={image.fluid}
          preserveStackingContext={true}
        >
          <GalleryItemOverlay className="stretched">
            <GalleryItemTitle>{title}</GalleryItemTitle>
            <GalleryItemSubtitle>{subtitle}</GalleryItemSubtitle>
          </GalleryItemOverlay>
        </BackgroundImage>
      </div>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  flex: 1 0 544px;
  min-width: 544px;
  max-width: calc(50% - var(--double-spacing));
  position: relative;
  display: block;
  margin: 8px;

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  @media screen and (max-width: 576px) {
    max-width: 100%;
  }
`

const GalleryItemOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s;
  padding: var(--double-spacing);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  &:hover {
    opacity: 1;
  }
`

const GalleryItemTitle = styled.span`
  margin-bottom: 0.3em;
  font-weight: var(--high-emphasis-font-weight);
  font-family: var(--high-emphasis-font-family);
  text-transform: uppercase;
`

const GalleryItemSubtitle = styled.span`
  max-width: 80%;
`
