import styled from "styled-components"

const Jumbotron = styled.div`
  text-align: center;
  font-size: 32px;
  line-height: 39px;
  padding: 0 var(--content-padding-vertical);
  margin: 0 auto;
  max-width: 800px;
  font-family: var(--jumbotron-font-family);
  color: var(--medium-emphasis-color);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Jumbotron
